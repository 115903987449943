<template>
    <header class="header">
      <nav class="navbar">
        <div class="navbar-menu">
          <router-link to="/" class="logo">
            kinczyk.art
          </router-link>
        </div>
        <div class="navbar-end">
          <a class="navbar-item" @click="scrollToAbout">O mnie</a>
          <a class="navbar-item" @click="scrollToContact">Kontakt</a>
        </div>
      </nav>
    </header>
  </template>
  
  <script>
  export default {
    methods: {
      scrollToAbout() {
        const aboutSection = document.getElementById('about-section');
        aboutSection.scrollIntoView({ behavior: 'smooth' });
      },
      scrollToContact() {
        const contactSection = document.getElementById('contact-section');
        contactSection.scrollIntoView({ behavior: 'smooth' });
      },
    },
  };
  </script>
<style>
    /* Style dla nagłówka */
    .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    }

    .logo {
    font-size: 24px;
    color: #ffffff; /* Kolor logo */
    font-weight: bold;
    text-transform: uppercase;
    }

    .navbar-end a{
    font-size: 24px;
    color: #bdbdbd; /* Kolor logo */
    }

    nav {
    display: flex;
    }

    nav a {
    text-decoration: none;
    color: #666; /* Kolor linków w nawigacji */
    margin-right: 20px;
    }

    nav a:hover {
    color: #ff8c00; /* Kolor linków w nawigacji po najechaniu */
    }
</style>