<template>
  <div class="app">
    <Header />

    <main>

      <div class="gallery">
        <div class="gallery-grid" ref="galleryGrid">
          <div 
            v-for="(image, index) in images" 
            :key="index" 
            
            :class="['gallery-item', { 'gallery-item--landscape': imageIsLandscape(image) }]"
            @click="openModal(image)"
            >
            <img 
              :data-src="image.filename" 
              :alt="image.alt" 
              class="art-item"
              ref="imageRef" 
              >
          </div>
        </div>

        <ModalImage
          :showModal="showModal"
          :imageSrc="modalImageSrc"
          :alt="modalImageAlt"
          @close="closeModal"
        />

      </div>

      <!-- Sekcja "O mnie" -->
      <section id="about-section" class="about">
        <h2 class="section-heading">O mnie</h2>
        <!-- Krótka biografia artysty -->
        <div class="bio">
          <p>
            Jestem artystką zafascynowaną nowoczesnym nurtem sztuki abstrakcyjnej. Moje obrazy czerpią inspirację z kolorów, form i emocji, tworząc niepowtarzalne dzieła, które wywołują refleksję i emocje u widza.
          </p>
        </div>
        <!-- Zdjęcie artysty -->
        <img src="@/assets/Justyna1.jpg" alt="Artysta" class="artist-photo">
      </section>
    </main>

    <footer id="contact-section" class="footer">
      <!-- Stopka z informacjami kontaktowymi -->
      <div class="contact-info">
        <p>E-mail: just.kinczyk@gmail.com</p>
        <p>Telefon: +48 605 088 684</p>
      </div>
      <!-- Linki do mediów społecznościowych artysty -->
      <!-- <div class="social-links">
        <a href="#" class="social-link">Facebook</a>
        <a href="#" class="social-link">Instagram</a>
      </div> -->
    </footer>
    <button class="float-button" @click="scrollToTop">
      <span>^</span> <!-- Dodaj ikonę strzałki w górę z Font Awesome -->
    </button>
  </div>
</template>

<script>
import ModalImage from "@/components/ModalImage.vue"; // Import nowo utworzonego komponentu
import Header from "@/components/Header.vue"; 
export default {
  name: "App",
  components: {
    ModalImage,
    Header
  },
  data() {
    return {
      showModal: false, // Stan modala, początkowo ukryty
      modalImageSrc: "", // Src obrazu do wyświetlenia w modalu
      modalImageAlt: "", // Tekst alternatywny dla obrazu w modalu
      images: [
        // { filename: "IMG_20200713_171244.jpg", alt: "Obraz 1" },
        // { filename: "IMG_20210228_164819.jpg", alt: "Obraz 2" },
        // { filename: "IMG_20220718_093658.jpg", alt: "Obraz 3" },
        { filename: "xDSC_7734.jpg", alt: "Obraz 4" },
        { filename: "xDSC_7737.jpg", alt: "Obraz 5" },
        { filename: "xDSC_7741.jpg", alt: "Obraz 6" },
        { filename: "xDSC_7745.jpg", alt: "Obraz 7" },
        { filename: "xDSC_7746.jpg", alt: "Obraz 8" },
        { filename: "xDSC_7751.jpg", alt: "Obraz 9" },
        { filename: "xDSC_7753.jpg", alt: "Obraz 10" },
        { filename: "xDSC_7754.jpg", alt: "Obraz 11" },
        { filename: "xDSC_7757.jpg", alt: "Obraz 12" },
        { filename: "xDSC_7761.jpg", alt: "Obraz 13" },
        { filename: "xDSC_7764.jpg", alt: "Obraz 14" },
        { filename: "xDSC_7766.jpg", alt: "Obraz 15" },
        { filename: "xDSC_7767.jpg", alt: "Obraz 16" },
        { filename: "xDSC_7770.jpg", alt: "Obraz 17" },
        { filename: "xDSC_7777.jpg", alt: "Obraz 18" },
        { filename: "xDSC_7781.jpg", alt: "Obraz 19" },
        { filename: "xDSC_7787.jpg", alt: "Obraz 20" },
        { filename: "xDSC_7789.jpg", alt: "Obraz 21" },
        { filename: "xDSC_7793.jpg", alt: "Obraz 22" },
        { filename: "xDSC_7794.jpg", alt: "Obraz 23" },
        { filename: "xDSC_7799.jpg", alt: "Obraz 24" },
        { filename: "xDSC_7801.jpg", alt: "Obraz 25" },
        { filename: "xDSC_7803.jpg", alt: "Obraz 26" },
        { filename: "xDSC_7805.jpg", alt: "Obraz 27" },
        { filename: "xDSC_7806.jpg", alt: "Obraz 28" },
        { filename: "xDSC_7816.jpg", alt: "Obraz 29" },
        { filename: "xDSC_7821.jpg", alt: "Obraz 30" },
        { filename: "xDSC_7823.jpg", alt: "Obraz 31" },
        { filename: "xDSC_7824.jpg", alt: "Obraz 32" },
        { filename: "xDSC_7827.jpg", alt: "Obraz 33" },
        { filename: "xDSC_7829.jpg", alt: "Obraz 34" },
        { filename: "xDSC_7832.jpg", alt: "Obraz 35" },
        { filename: "xDSC_8874.jpg", alt: "Obraz" },
        { filename: "xDSC_8875.jpg", alt: "Obraz" },
        { filename: "xDSC_8876.jpg", alt: "Obraz" },
        { filename: "xDSC_8879.jpg", alt: "Obraz" },
        { filename: "xDSC_8881.jpg", alt: "Obraz" },
        { filename: "xDSC_8883.jpg", alt: "Obraz" },
        { filename: "xDSC_8884.jpg", alt: "Obraz" },
        { filename: "xDSC_8887.jpg", alt: "Obraz" },
        { filename: "xDSC_8890.jpg", alt: "Obraz" },
        { filename: "xDSC_8893.jpg", alt: "Obraz" },
        { filename: "xDSC_8898.jpg", alt: "Obraz" },
        { filename: "xDSC_8907.jpg", alt: "Obraz" },
        { filename: "xDSC_8911.jpg", alt: "Obraz" },
        { filename: "xDSC_8912.jpg", alt: "Obraz" },
        { filename: "xDSC_8914.jpg", alt: "Obraz" },
        { filename: "xDSC_8917.jpg", alt: "Obraz" },
        { filename: "xDSC_8919.jpg", alt: "Obraz" },
        { filename: "xDSC_8923.jpg", alt: "Obraz" },
        { filename: "xDSC_8929.jpg", alt: "Obraz" },
        { filename: "xDSC_8933.jpg", alt: "Obraz" },
        { filename: "xDSC_8937.jpg", alt: "Obraz" },
        { filename: "xDSC_8943.jpg", alt: "Obraz" },
        { filename: "xDSC_8944.jpg", alt: "Obraz" },
        { filename: "xDSC_8948.jpg", alt: "Obraz" },
        { filename: "xDSC_8952.jpg", alt: "Obraz" },
        { filename: "xDSC_8953.jpg", alt: "Obraz" },
        { filename: "xDSC_8955.jpg", alt: "Obraz" },
        { filename: "xDSC_8962.jpg", alt: "Obraz" },
        { filename: "xDSC_8967.jpg", alt: "Obraz" },
        { filename: "xDSC_8970.jpg", alt: "Obraz" },
        { filename: "xDSC_8972.jpg", alt: "Obraz" },
        { filename: "xDSC_8984.jpg", alt: "Obraz" },
        { filename: "xDSC_8987.jpg", alt: "Obraz" },
        { filename: "xDSC_8992.jpg", alt: "Obraz" },
        
      ],
      isLandscape: [], // Lista, która będzie przechowywać informacje o orientacji zdjęć

    };
  },
  methods: {

    // Sprawdza, czy obraz jest w orientacji poziomej
    imageIsLandscape(image) {
      const img = new Image();
      img.src = require(`@/assets/${image.filename}`);
      console.log(img.src, img.width, img.height)
      return img.width > img.height;
    },

    // Metoda, która sprawdza orientację zdjęć
    checkImageOrientation() {
      this.images.forEach((image) => {
        const img = new Image();
        img.src = require(`@/assets/${image.filename}`);
        this.isLandscape.push(img.width > img.height); // Dodaj informację o orientacji do listy
      });
    },

    // Otwórz modal z wybranym zdjęciem
    openModal(image) {
      this.modalImageSrc = require(`@/assets/${image.filename}`);
      this.modalImageAlt = image.alt;
      this.$router.push({ query: { modalImage: image.filename } });
      this.showModal = true;
    },

    // Zamknij modal
    closeModal() {
      this.$router.push({ query: undefined });
      this.showModal = false;
    },

    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
  },
  mounted() {
    const imageRefs = this.$refs.imageRef; // Pobierz wszystkie referencje do zdjęć

    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1, // Procent elementu w widoku, po którym zostanie załadowane zdjęcie
    };

    const imageObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Jeśli element jest w widoku, załaduj zdjęcie
          const imageElement = entry.target;
          const imageUrl = imageElement.getAttribute('data-src');

          if (imageUrl) {
            imageElement.setAttribute('src', require(`@/assets/${imageUrl}`));
            imageElement.removeAttribute('data-src'); // Usuń atrybut data-src, aby uniknąć ponownego ładowania
            imageObserver.unobserve(imageElement); // Przestań obserwować zdjęcie, gdy zostało załadowane
          }
        }
      });
    }, options);

    // Zacznij obserwować wszystkie zdjęcia
    imageRefs.forEach((imageRef) => {
      imageObserver.observe(imageRef);
    });

    // Wywołaj metodę, aby sprawdzić orientację zdjęć
    this.checkImageOrientation();

    if (this.$route.query.modalImage) {
      const imageFromQuery = this.images.find(image => image.filename === this.$route.query.modalImage)
        if (imageFromQuery) this.openModal(imageFromQuery)
    }

  },
  // watch: {
  //   $route(to, from) {
  //     // Sprawdź, czy istnieje parametr 'modalImage' w Query String URL-a
  //     if (to.query.modalImage) {
  //       // Otwórz modal i przekaż odpowiednie zdjęcie
  //       const imageFromQuery = this.images.find(image => image.filename === to.query.modalImage)
  //       if (imageFromQuery) this.openModal(imageFromQuery)
  //     } else {
  //       // Zamknij modal, jeśli parametr nie jest obecny w URL-u
  //       this.showModal = false;
  //     }
  //   },
  // },
};
</script>

<style>
/* Style dla całej strony */
body {
  font-family: 'Helvetica Neue', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f5f5f5; /* Stonowane tło strony */
}

.app {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #111; /* Tło głównego kontenera */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Cień do oddzielenia od tła strony */
  border-radius: 8px; /* Zaokrąglenie rogów kontenera */
}

.float-button {
  position: fixed;
  bottom: 30px;
  right: 30px;
  background-color: #444;
  color: white;
  border: none;
  padding: 10px 15px;
  font-size: 24px;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* Style dla galerii */
.gallery {
  margin-top: 40px;
  background-color: #333; /* Tło galerii */
  padding: 20px;
  border-radius: 8px; /* Zaokrąglenie rogów galerii */
  overflow: hidden; /* Ukryj ewentualne przewijanie */
}

/* Ustawienie wyświetlania trzech obrazów w rzędzie dla różnych rozmiarów ekranu */
.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;
}

/* Styl dla obiektów w galerii */

.gallery-item {
  display: flex;
  justify-content: center; /* Wyśrodkuj obrazy w poziomie */
  align-items: center; /* Wyśrodkuj obrazy w pionie */
}

.gallery-item img {
  width: auto;
  height: auto;
  display: block; /* Wyśrodkuj obrazy wewnątrz elementu galerii */
  margin: 0 auto; /* Wycentruj obrazy wewnątrz elementu galerii */
  max-height: 500px; /* Maksymalna wysokość obrazu w galerii */
  background-color: #333;
}

.gallery-item--landscape {
  grid-column-end: span 2;
  max-height: 500px; /* Ogranicz wysokość poziomych zdjęć, aby nie były zbyt duże */

}

@media (max-width: 768px) {
  .gallery-item--landscape {
    grid-column-end: span 1;
    /* max-height: auto; */
  }
  .gallery-item--landscape img {
    max-width: 100%;
  }
}

/* Style dla sekcji "O mnie" */
.about {
  margin-top: 40px;
}

.section-heading {
  font-size: 28px;
  color: #b6b6b6; /* Kolor nagłówka sekcji */
  margin-bottom: 20px;
}

.bio {
  line-height: 1.6;
}

.artist-photo {
  width: 100%;
  max-width: 300px;
  height: auto;
  border-radius: 8px;
  margin-top: 20px;
  box-shadow: 0 4px 8px rgba(255, 255, 255, 0.1);
}

/* Style dla stopki */
.footer {
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contact-info, .social-links {
  text-align: center;
}

.social-link {
  text-decoration: none;
  color: #666; /* Kolor linków w stopce */
  margin-right: 10px;
}

.social-link:hover {
  color: #000; /* Kolor linków w stopce po najechaniu */
}

/* Przykład stylu dla tekstu */
p, h1, h2, h3, h4, h5, h6 {
  color: #ffffff;
}

/* Przykład stylu dla linków */
a {
  color: #ddd;
}

/* Przykład stylu dla przycisków */
.button {
  background-color: #444;
  color: #ffffff;
}

/* Skalowanie zdjęć na urządzeniach mobilnych */
@media (max-width: 768px) {
  .gallery-wrapper {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}

</style>
