<template>
    <div class="modal-image" v-if="showModal" >
        <div class="close-button" @click="closeModal">
            <span>X</span>
        </div>
        <div class="share-button" @click="shareImage">
            <span>Udostępnij</span>
        </div>
        <!-- <button class="share-button" @click="shareImage">Udostępnij</button> -->
        <img :src="imageSrc" :alt="alt" class="modal-content" @click.stop="">
        <!-- <div>
            <p>
                Testowy tekst
            </p>
        </div> -->
    </div>
  </template>
  
  <script>

  export default {

    props: {
      showModal: Boolean,
      imageSrc: String,
      alt: String,
    },
    methods: {
      closeModal() {
        console.log('close!')
        this.$emit("close");
      },
      shareImage() {
      // Pobierz URL zdjęcia na podstawie jego ID
      const imageUrl = this.imageSrc;

      // Sprawdź, czy API navigator.share jest dostępne
      if (navigator.share) {
        // Udostępnij za pomocą API navigator.share
        navigator.share({
          title: 'Kinczyk.art - Udostępnienie zdjęcia',
          text: 'Sprawdź to niesamowite zdjęcie!',
          url: imageUrl,
        });
      } else {
        // Wyświetl URL w konsoli w razie braku wsparcia dla API navigator.share
        console.log('Zdjęcie można skopiować poprzez poniższy URL:');
        console.log(imageUrl);

        // Opcjonalnie można także wyświetlić komunikat użytkownikowi w przypadku braku wsparcia dla API navigator.share
        alert('Niestety, twoja przeglądarka nie obsługuje funkcji udostępniania.');
      }
    },
    },
  };
  </script>
  
  <style scoped>
  .modal-image {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 999;
  }
  
  .modal-content {
    max-width: 100%;
    max-height: 100%;
    cursor: pointer;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.8); /* Dodanie cienia do modalu */
  }

  .close-button {
    position: absolute;
    top: 2vw; /* Użycie jednostki viewport */
    right: 2vw; /* Użycie jednostki viewport */
    z-index: 1000;
    font-size: 4vw; /* Użycie jednostki viewport */
    cursor: pointer;
    color: white;
    }

    .share-button {
    position: absolute;
    top: 2vw; /* Użycie jednostki viewport */
    left: 2vw; /* Użycie jednostki viewport */
    z-index: 1000;
    font-size: 2vw; /* Użycie jednostki viewport */
    cursor: pointer;
    color: white;
    }


  /* Styl dodany tylko na urządzeniach mobilnych */
  @media (max-width: 768px) {
    .modal-image {
      background-color: black;
    }
    .close-button {
        font-size: 8vw; /* Inna wartość dla urządzeń mobilnych */
    }
  }
  </style>
  